import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Signature from "@uiw/react-signature";

import { onSubmitForm } from "../api";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

export const Form35 = (props) => {
  const { t } = useTranslation();

  const FormSchema = Yup.object().shape({
    firstName: Yup.string().required(t("errors.required")),
    lastName: Yup.string().required(t("errors.required")),
    fatherInitial: Yup.string().required(
      t("errors.required")
    ),
    street: Yup.string().required(t("errors.required")),
    number: Yup.string().required(t("errors.required")),
    block: Yup.string().required(t("errors.required")),
    buildingEntrance: Yup.string().required(
      t("errors.required")
    ),
    floor: Yup.string().required(t("errors.required")),
    apartment: Yup.string().required(t("errors.required")),
    county: Yup.string().required(t("errors.required")),
    city: Yup.string().required(t("errors.required")),
    postalCode: Yup.string().required(t("errors.required")),
    cnp: Yup.string()
      .min(13, t("errors.min13"))
      .required(t("errors.required")),
    email: Yup.string()
      .email(t("errors.email"))
      .required(t("errors.required")),
    phone: Yup.string().required(t("errors.required")),
  });

  const $svg = React.useRef(null);
  const handle = (evn) => $svg.current?.clear();

  const { description, title } = props;

  const empty = {
    firstName: "",
    lastName: "",
    fatherInitial: "",
    street: "",
    number: "",
    block: "",
    buildingEntrance: "",
    floor: "",
    apartment: "",
    county: "",
    city: "",
    postalCode: "",
    cnp: "",
    email: "",
    phone: "",
    twoYears: false,
    gdpr: false,
    promotions: false,
  };

  const test = {
    firstName: "s",
    lastName: "s",
    fatherInitial: "s",
    street: "s",
    number: "s",
    block: "s",
    buildingEntrance: "s",
    floor: "s",
    apartment: "s",
    county: "s",
    city: "s",
    postalCode: "s",
    cnp: "8888888888888888",
    email: "S@s.com",
    phone: "s",
    twoYears: false,
    gdpr: false,
    promotions: false,
  };

  return (
    <>
      <h2 className="form-35-title">{title}</h2>
      <div className="form-35-description">
        {description}
      </div>
      <div className="form-35-wrapper">
        <Formik
          initialValues={empty}
          onSubmit={(
            values,
            { setSubmitting, resetForm }
          ) => {
            setSubmitting(false);

            onSubmitForm(values)
              .then((res) => res.json())
              .then((data) => {
                resetForm();
                handle();
              });
          }}
          validationSchema={FormSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="formik-35-form-wrapper">
                <FormLine>
                  <FormField
                    inputName="firstName"
                    inputType="text"
                    label={t("form35.firstName")}
                    placeholder={t("form35.firstName")}
                  />

                  <FormField
                    inputName="fatherInitial"
                    inputType="text"
                    label={t("form35.fatherInitial")}
                    placeholder={t("form35.fatherInitial")}
                  />

                  <FormField
                    inputName="lastName"
                    inputType="text"
                    label={t("form35.lastName")}
                    placeholder={t("form35.lastName")}
                  />
                </FormLine>

                <FormLine>
                  <FormField
                    inputName="street"
                    inputType="text"
                    label={t("form35.street")}
                    placeholder={t("form35.street")}
                  />
                  <FormField
                    inputName="number"
                    inputType="text"
                    label={t("form35.number")}
                    placeholder={t("form35.number")}
                  />
                </FormLine>

                <FormLine>
                  <FormField
                    inputName="block"
                    inputType="text"
                    label={t("form35.block")}
                    placeholder={t("form35.block")}
                  />
                  <FormField
                    inputName="buildingEntrance"
                    inputType="text"
                    label={t("form35.buildingEntrance")}
                    placeholder={t(
                      "form35.buildingEntrance"
                    )}
                  />
                  <FormField
                    inputName="floor"
                    inputType="text"
                    label={t("form35.floor")}
                    placeholder={t("form35.floor")}
                  />
                  <FormField
                    inputName="apartment"
                    inputType="text"
                    label={t("form35.apartment")}
                    placeholder={t("form35.apartment")}
                  />
                </FormLine>

                <FormLine>
                  <FormField
                    inputName="county"
                    inputType="text"
                    label={t("form35.county")}
                    placeholder={t("form35.county")}
                  />
                  <FormField
                    inputName="city"
                    inputType="text"
                    label={t("form35.city")}
                    placeholder={t("form35.city")}
                  />
                  <FormField
                    inputName="postalCode"
                    inputType="text"
                    label={t("form35.postalCode")}
                    placeholder={t("form35.postalCode")}
                  />
                </FormLine>

                <FormLine>
                  <FormField
                    inputName="cnp"
                    inputType="text"
                    label={t("form35.cnp")}
                    placeholder={t("form35.cnp")}
                  />
                </FormLine>

                <FormLine>
                  <FormField
                    inputName="email"
                    inputType="text"
                    label={t("form35.email")}
                    placeholder={t("form35.email")}
                  />
                  <FormField
                    inputName="phone"
                    inputType="text"
                    label={t("form35.phone")}
                    placeholder={t("form35.phone")}
                  />
                </FormLine>

                <FormLine>
                  <FormField
                    inputName="twoYears"
                    inputType="checkbox"
                    label={t("form35.twoYears")}
                    placeholder={t("form35.twoYears")}
                  />
                </FormLine>

                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginBottom: "6px",
                  }}
                >
                  {t("form35.signatureTitle")}
                </div>
                <div
                  style={{
                    border: "1px solid black",
                    marginBottom: "6px",
                  }}
                >
                  <Signature
                    ref={$svg}
                    options={{
                      size: 4,
                      thinning: 0.1,
                      smoothing: 1,
                    }}
                  />
                </div>
                <button
                  onClick={handle}
                  style={{
                    width: "150px",
                    padding: "10px",
                    marginBottom: "12px",
                  }}
                >
                  {t("form35.signatureClearButtonLabel")}
                </button>

                <FormLine>
                  <FormField
                    inputName="gdpr"
                    inputType="checkbox"
                    label={t("form35.gdpr")}
                    placeholder={t("form35.gdpr")}
                  />
                </FormLine>
                <FormLine>
                  <FormField
                    inputName="promotions"
                    inputType="checkbox"
                    label={t("form35.promotions")}
                    placeholder={t("form35.promotions")}
                  />
                </FormLine>

                <button
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("form35.send")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const FormLine = (props) => {
  const { children } = props;
  return <div className="formik-line">{children}</div>;
};

const FormField = (props) => {
  const { inputName, inputType, label, placeholder } =
    props;

  return (
    <div className="formik-form-field">
      {inputType !== "checkbox" ? (
        <label htmlFor={inputName}>{label}</label>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Field
          id={inputName}
          type={inputType}
          name={inputName}
          placeholder={placeholder}
        />
        {inputType === "checkbox" ? (
          <label htmlFor={inputName}>{label}</label>
        ) : null}
      </div>
      <ErrorMessage
        name={inputName}
        component={FormFieldError}
      />
    </div>
  );
};

const FormFieldError = (props) => {
  const error = props.children;

  return <div className="formik-field-error">{error}</div>;
};
