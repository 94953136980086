import React from "react";

import ReactMarkdown from "react-markdown";

import { useTranslation } from "react-i18next";

export const MindLabArticles = () => {
  const [details, setDetails] = React.useState(null);

  const { i18n } = useTranslation();

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/mind-care-lab?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const { attributes } = data;
        // console.log(data);
        setDetails(attributes);

        // console.log(attributes.articlesFullList);
      });
  }, [i18n.language]);

  return (
    <div className="mindlab-articles">
      <div className="hero-section">
        <div className="hero-section-bg-image" />

        <div className="message">
          {details?.articlesListTitle}
        </div>
      </div>
      <div className="mindlab-articles-content">
        <ReactMarkdown>
          {details?.articlesFullList}
        </ReactMarkdown>
      </div>
    </div>
  );
};
