import React from "react";
import roFlag from "../img/ro_flag.webp";
import enFlag from "../img/uk_flag.png";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

export const LanguagePicker = () => {
  return (
    <div className="language-picker-wrapper">
      <Flag
        src={roFlag}
        language={"ro"}
        isActive={i18n.language === "ro"}
      />
      <Flag
        src={enFlag}
        language={"en"}
        isActive={i18n.language === "en"}
      />
    </div>
  );
};

const Flag = (props) => {
  const { src, language, isActive } = props;

  const { i18n } = useTranslation();

  return (
    <div
      onClick={() => {
        i18n.changeLanguage(language);
      }}
      className={`language-item ${
        !isActive ? "inactive" : ""
      }`}
    >
      <img
        src={src}
        className="language-picker-flag-item"
      />
    </div>
  );
};

export const MobileLanguagePicker = () => {
  return (
    <div className="mobile-language-picker-wrapper">
      <Flag
        src={roFlag}
        language={"ro"}
        isActive={i18n.language === "ro"}
      />
      <Flag
        src={enFlag}
        language={"en"}
        isActive={i18n.language === "en"}
      />
    </div>
  );
};
