import React from "react";

import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { newsletterSubscribe } from "../api";

export const Newsletter = () => {
  const [email, setEmail] = React.useState("");
  const [status, setStatus] = React.useState(-1);

  const [emailIsValid, setEmailIsValid] =
    React.useState(true);

  const [newsletterDetails, setNewsletterDetails] =
    React.useState({
      title: "",
      buttonLabel: "",
    });

  const { i18n, t } = useTranslation();

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/newsletter?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const { attributes } = data;

        setNewsletterDetails(attributes);
      });
  }, [i18n.language]);

  return null;

  return (
    <div className="newsletter-section">
      <div className="newsletter-wrapper">
        {status === -1 ? (
          <SubscribeToNewsletter
            title={newsletterDetails.title}
            email={email}
            setEmail={setEmail}
            buttonLabel={newsletterDetails.buttonLabel}
            setStatus={setStatus}
            emailIsValid={emailIsValid}
            setEmailIsValid={setEmailIsValid}
          />
        ) : null}

        {status === 1 ? (
          <AlreadySubscribed
            message={t("nesletter.alreadySubscribed")}
          />
        ) : null}
        {status === 0 ? (
          <SubscriptionSuccessfull
            message={t("newsletter.subscriptionSuccess")}
          />
        ) : null}
        {status === 9 ? (
          <>
            <SubscribeToNewsletter
              title={newsletterDetails.title}
              email={email}
              setEmail={setEmail}
              buttonLabel={newsletterDetails.buttonLabel}
              setStatus={setStatus}
              emailIsValid={emailIsValid}
              setEmailIsValid={setEmailIsValid}
            />

            <SubscriptionError
              message={t("newsletter.subscriptionError")}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

const validateEmail = (email) => {
  const re =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

const SubscribeToNewsletter = ({
  title,
  email,
  setEmail,
  buttonLabel,
  setStatus,
  emailIsValid,
  setEmailIsValid,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="title-wrapper">{title}</div>
      <div className="newsletter-form">
        <TextField
          placeholder="email"
          label="email"
          variant="outlined"
          sx={{
            flex: 1,
            maxWidth: "500px",
          }}
          size="small"
          value={email}
          onChange={(e) => {
            if (!emailIsValid) {
              setEmailIsValid(true);
            }
            setEmail(e.target.value);
          }}
          helperText={
            emailIsValid ? "" : t("newsletter.invalidEmail")
          }
          error={!emailIsValid}
        />
        <div className="newsletter-button-wrapper">
          <Button
            variant="contained"
            sx={{
              background: "#FFFFFF !important",
              color: "black",
              height: "40px",
              textTransform: "none",
            }}
            onClick={() => {
              if (!validateEmail(email)) {
                setEmailIsValid(false);
              } else {
                newsletterSubscribe({ email })
                  .then((res) => {
                    // console.log(res);

                    return res.json();
                    // res.json();
                  })
                  .then((data) => {
                    // console.log(data);
                    const { status } = data;

                    setStatus(status);
                  })
                  .catch((error) => console.log(error));
              }
            }}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </>
  );
};

const SubscriptionMessage = ({ message }) => (
  <>
    <div style={{ textAlign: "center" }}>{message}</div>
  </>
);

const AlreadySubscribed = ({ message }) => (
  <>
    <div style={{ textAlign: "center" }}>{message}</div>
  </>
);

const SubscriptionSuccessfull = ({ message }) => (
  <div style={{ textAlign: "center" }}>{message}</div>
);

const SubscriptionError = ({ message }) => (
  <div v style={{ textAlign: "center", marginTop: 20 }}>
    {message}
  </div>
);
