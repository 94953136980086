import React from "react";

import { Newsletter } from "./Newsletter";

import { useTranslation } from "react-i18next";

export const WhoWeAre = () => {
  const [textOverImage, setTextOverImage] =
    React.useState("");
  const [textDescription, setTextDescription] =
    React.useState("");

  const { i18n } = useTranslation();

  const [members, setMembers] = React.useState([
    {
      description: "",
      image: {
        data: {
          attributes: {
            formats: {
              thumbnail: { url: null },
            },
            url: null,
          },
        },
      },
      name,
    },
    {
      description: "",
      image: {
        data: {
          attributes: {
            formats: {
              thumbnail: { url: null },
            },
            url: null,
          },
        },
      },
      name,
    },
    {
      description: "",
      image: {
        data: {
          attributes: {
            formats: {
              thumbnail: { url: null },
            },
            url: null,
          },
        },
      },
      name,
    },
  ]);

  React.useLayoutEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/who-we-are?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const {
          attributes: {
            textOverImage,
            textDescription,
            members,
          },
        } = data;

        setTextOverImage(textOverImage);
        setTextDescription(textDescription);
        setMembers(members);
      });
  }, [i18n.language]);

  const membersImageStyles = [
    { objectPosition: "0% 0%" },
    { objectPosition: "0% 0%" },
    { objectPosition: "0% 0%" },
  ];

  return (
    <div className="who-we-are-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />
        <div className="message">{textOverImage}</div>
      </div>

      <div className="page-title-section">
        {textDescription}
      </div>

      <div className="team-members-wrapper">
        {members.map(
          (
            {
              description,
              nameAndTitle,
              image: {
                data: {
                  attributes: {
                    formats: {
                      thumbnail: { url: thumbnailURL },
                    },
                    url,
                  },
                },
              },
              LinkedIN_button_text: linkedINButtonText,
              LinkedIN_link: linkedINLink,
              CV_button_text: CVButtonText,
              CV_link: CVLink,
            },
            index
          ) => {
            return (
              <TeamMember
                key={index}
                image={url}
                thumbnailURL={thumbnailURL}
                description={description}
                name={nameAndTitle}
                customStyles={membersImageStyles[index]}
                linkedINButtonText={linkedINButtonText}
                linkedINLink={linkedINLink}
                CVButtonText={CVButtonText}
                CVLink={CVLink}
              />
            );
          }
        )}
        {/* <TeamMember
          image={pic1}
          name={"Conf. Univ. Dr. Ioana Podina"}
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
          imagePosition={{ objectPosition: "0% 10%" }}
        />
        <TeamMember
          image={pic2}
          name={"Drd. Diana Todea"}
          description="Diana Todea este student doctorand în cadrul cadrul Școlii Doctorale Interdisciplinare a Universității din București. Este licențiată ca psiholog și formată în psihoterapie. Are o experiență psiho-oncologică de 7 ani în cadrul Fundației Little People și a Institutului Oncologic Prof. Dr. Ion Chiricuță. A absolvit un masterat în Psihologie Clinică, Consiliere psihologică și Psihoterapie la Universitatea Babeș-Bolyai, Cluj-Napoca. În proiectul curent, Diana utilizează cunoștințele de psiho-oncologie în scopul îmbunătățirii agentului."
          //   imagePosition={{ objectPosition: "50% 0%" }}
        />
        <TeamMember
          image={pic3}
          name={"Drd. Ștefania Ștefanache"}
          description="Ștefania Ștefanache a parcurs un drum academic și profesional profund ancorat în domeniul psihologiei, începând cu absolvirea Facultății de Psihologie și Științele Educației și continuând cu un Master în Psihologie Clinică, Consiliere Psihologică și Psihoterapie la Universitate Babeș-Bolyai. În prezent, își aprofundează cercetările și cunoștințele ca doctorand la Școala Doctorală Interdisciplinară ISDS a Universității din București. În calitate de psiholog clinician și psihoterapeut atestat de Colegiul Psihologilor din România, se definește ca un “scientist practitioner” - un practician care integrează tehnici validate științific în abordarea terapeutică, conform cu cele mai actuale protocoale internaționale de intervenție psihologică. Experiența sa se bazează pe o combinație echilibrată de abilități interpersonale, esențiale pentru a empatiza și a se conecta cu dificultățile întâmpinate de clienți, având abilități analitice, care îi permit să identifice cele mai eficiente soluții adaptate nevoilor individuale."
          imagePosition={{ objectPosition: "0% 30%" }}
        /> */}
      </div>
      {/* <Newsletter /> */}
    </div>
  );
};

const TeamMember = (props) => {
  const {
    image,
    thumbnailURL,
    name,
    description,
    imagePosition,
    customStyles,
    linkedINButtonText,
    linkedINLink,
    CVButtonText,
    CVLink,
  } = props;

  const imageRef = React.useRef(null);
  const imageLoaded = React.useRef(false);

  const [isExpanded, setIsExpanded] = React.useState(false);

  const { t } = useTranslation();

  return (
    <div className="team-member-item">
      <div className="team-member-left-column">
        <div className="image-box-wrapper">
          <img
            ref={imageRef}
            src={thumbnailURL}
            style={{
              opacity: 0,
              transition: "1s all",
              ...customStyles,
            }}
            onLoad={() => {
              if (!imageLoaded.current) {
                imageRef.current.src = image;
                imageRef.current.style.opacity = "1";
                imageLoaded.current = true;
              }
            }}
          />
        </div>
        <div className="name-and-title">{name}</div>
        <div className="linkedin-wrapper">
          <a href={linkedINLink} target="_BLANK">
            {linkedINButtonText}
          </a>
        </div>
        <div className="cv-wrapper">
          <a href={CVLink} target="_BLANK">
            {CVButtonText}
          </a>
        </div>
      </div>
      <div
        className={`team-member-description  ${
          isExpanded ? "long-version" : "short-version"
        }`}
      >
        <div className={`content`}>{description}</div>
        <div
          className="read-more-wrapper"
          onClick={() => {
            setIsExpanded(true);
          }}
        >
          {t("article.readMore")}
        </div>
      </div>
    </div>
  );
};
