import React from "react";

import { useTranslation } from "react-i18next";
import coverImage from "../img/articleCover1.jpg";

import { useNavigate } from "react-router-dom";

export const Blog = () => {
  const [details, setDetails] = React.useState({});
  const [articles, setArticles] = React.useState([]);

  const { i18n } = useTranslation();

  const navigate = useNavigate();

  React.useLayoutEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/blog?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const { attributes } = data;

        setDetails(attributes);
      })
      .catch((res) => {
        navigate("/");
        console.log("ERROR BLOG");
      });

    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/articles?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        // console.log(data);
        setArticles(data.reverse());
      })
      .catch((res) => {
        navigate("/");
        console.log("ERROR");
      });
  }, [i18n.language]);

  return (
    <div className="blog-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />
        <div className="message">
          {details.textOverImage}
        </div>
      </div>

      {/* <div className="page-title-section">
        {details.textDescription}
      </div> */}

      <div className="articles-wrapper">
        {articles.map((article, index) => {
          const {
            attributes: {
              content,
              highlights,
              image: {
                data: {
                  attributes: {
                    formats: {
                      small: { url },
                    },
                  },
                },
              },
              title,
            },
            id,
          } = article;
          return (
            <Article
              key={index}
              image={url}
              title={title}
              content={content}
              highlights={highlights}
              id={id}
            />
          );
        })}
        {/* <Article
          image={coverImage}
          title="Primul titlu de articol care poate fi mai lung sau mai scurt, depinde"
          highlights="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        />
        <Article
          image={coverImage}
          title="Al doilea titlu de articol"
          highlights="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        />
        <Article
          image={coverImage}
          title="Al treilea dar nu cel din urma titlu de articol"
          highlights="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        /> */}
      </div>
    </div>
  );
};

const Article = (props) => {
  const { id, image, title, highlights } = props;

  const nagivate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className="article-wrapper">
      <div
        className="article-cover-image"
        onClick={() => {
          nagivate(`/article/${id}`);
        }}
      >
        <img src={image} />
      </div>
      <div className="article-content-wrapper">
        <div className="article-title">{title}</div>
        <div className="article-highlights">
          {highlights}
        </div>
        <div
          className="read-more-wrapper"
          onClick={() => {
            nagivate(`/article/${id}`);
          }}
        >
          {t("article.readMore")} {`>>`}
        </div>
      </div>
    </div>
  );
};
