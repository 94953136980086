import React from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Form35 } from "./Form35";

import { useTranslation } from "react-i18next";

export const Donate = () => {
  const { i18n, t } = useTranslation();

  const [details, setDetails] = React.useState(null);

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/donate?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        setDetails(data.attributes);
      });
  }, [i18n.language]);

  return (
    <div className="donate-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />
        <div className="message">
          {details?.textOverImage}
        </div>
      </div>

      <div className="donate-page-content-wrapper">
        <ReactMarkdown>{details?.content}</ReactMarkdown>

        <button
          style={{
            width: "150px",
            padding: "10px",
            marginTop: "20px",
          }}
          onClick={() => {
            window.open(
              "https://buy.stripe.com/fZe02E8mDaVa1gI4gh",
              "_BLANK"
            );
          }}
        >
          {t("donate.donateButtonLabel")}
        </button>
        <div
          style={{
            fontSize: "12px",
            fontStyle: "italic",
            marginTop: "4px",
          }}
        >
          {t("donate.disclaimerStrapi")}{" "}
          <a
            href="https://stripe.com/en-ro"
            target="_BLANK"
          >
            Stripe
          </a>
        </div>
      </div>
    </div>
  );
};
