import React from "react";

import research from "../img/research.svg";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useTranslation } from "react-i18next";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const MindLab = () => {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/mind-care-lab?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          const {
            data: { attributes },
          } = response;
          setDetails(attributes);
        }
      });
  }, [i18n.language]);

  const [details, setDetails] = React.useState(null);

  return (
    <div className="mindlab-page">
      <div className="hero-section">
        <div className="hero-section-bg-image"></div>
        <div className="message">
          {details?.textOverImage}
        </div>
      </div>

      <div className="page-title-section">
        {details?.textDescription}
      </div>

      <VideoSection url={details?.youtubeLINK} />

      <ResearchSection
        title={details?.researchSectionTitle}
        items={details?.researchItem}
      />
      <StatisticsSection items={details?.statisticalItem} />
      <ArticlesSection
        articles={details?.externalArticle}
      />

      <ArticlesListSection
        title={details?.articlesListTitle}
        articlesDesktopPreview={
          details?.articlesDesktopPreview
        }
        articlesMobilePreview={
          details?.articlesMobilePreview
        }
      />
      <BooksListSection
        title={details?.booksAndChaptersListTitle}
        booksAndChaptersMobilePreview={
          details?.booksAndChaptersMobilePreview
        }
        booksAndChaptersDesktopPreview={
          details?.booksAndChaptersDesktopPreview
        }
      />
    </div>
  );
};

const VideoSection = ({ url }) => {
  return (
    <div className="video-container">
      <iframe
        // width="100%"
        height="100%"
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const ResearchSection = ({ title, items }) => {
  return (
    <>
      <div className="section-title">{title}</div>

      <div className="research-grid">
        {items &&
          items.map((item, index) => {
            return (
              <ResearchItem
                title={item.title}
                icon={item?.icon?.data?.attributes?.url}
                description={item.description}
                key={index}
              />
            );
          })}
      </div>
    </>
  );
};

const StatisticsSection = ({ items }) => {
  return (
    <div className="statistics-grid">
      {items &&
        items.map((item, index) => {
          return (
            <StatisticsItem
              firstLine={item.number}
              secondLine={item.label}
              key={index}
            />
          );
        })}
      {/* <StatisticsItem
        firstLine="30+"
        secondLine="articole ISI-WOS publicate"
      />

      <StatisticsItem
        firstLine="30+"
        secondLine="conferințe"
      />
      <StatisticsItem
        firstLine="15+"
        secondLine="capitole de cărți"
      /> */}
    </div>
  );
};

const ArticlesSection = ({ articles }) => {
  return (
    <div className="articles-section">
      <div className="desktop-articles-wrapper">
        {articles &&
          articles.map((article, index) => {
            const { title, highlights, articleURL } =
              article;
            const image =
              article?.image?.data?.attributes?.url;

            return (
              <ExternalArticle
                key={index}
                image={image}
                title={title}
                articleURL={articleURL}
              />
            );
          })}
      </div>
      <div className="mobile-articles-wrapper">
        {articles ? (
          <Carousel
            draggable
            swipeable
            partialVisible
            responsive={{
              tablet: {
                breakpoint: { max: 1000, min: 500 },
                items: 2,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: { max: 500, min: 0 },
                items: 1,
                partialVisibilityGutter: 40,
              },
            }}
          >
            {articles &&
              articles.map((article, index) => {
                const { title, highlights, articleURL } =
                  article;
                const image =
                  article?.image?.data?.attributes?.url;

                return (
                  <ExternalArticle
                    key={index}
                    image={image}
                    title={title}
                    articleURL={articleURL}
                  />
                );
              })}
          </Carousel>
        ) : null}
      </div>
    </div>
  );
};

const ResearchItem = (props) => {
  const { icon, title, description } = props;

  return (
    <div className="research-item">
      <img src={icon} />
      <div className="research-item-title">{title}</div>
      <div className="research-item-description">
        {description}
      </div>
    </div>
  );
};

const StatisticsItem = (props) => {
  const { firstLine, secondLine } = props;

  return (
    <div className="statistics-item">
      <div className="first-line">{firstLine}</div>
      <div className="second-line">{secondLine}</div>
    </div>
  );
};

const ExternalArticle = (props) => {
  const {
    articleURL,
    image,
    title,
    customStyles = {},
  } = props;

  const { t } = useTranslation();

  return (
    <div className="article-wrapper">
      <div
        className="article-image"
        onClick={() => {
          window.open(articleURL, "_BLANK");
        }}
      >
        <img src={image} />
      </div>
      <div className="article-content">
        <div className="article-title">{title}</div>

        <div
          className="article-see-more"
          onClick={() => {
            window.open(articleURL, "_BLANK");
          }}
        >
          {t("article.readFull")}
        </div>
      </div>
    </div>
  );
};

const ArticlesListSection = ({
  title,
  articlesDesktopPreview,
  articlesMobilePreview,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="articles-list-section">
      <div className="title">{title}</div>
      <div className="desktop-articles-list">
        {articlesDesktopPreview ? (
          <div className="content">
            <ReactMarkdown>
              {articlesDesktopPreview}
            </ReactMarkdown>
            <div
              className="see-all-button"
              onClick={() => {
                navigate("/articles");
              }}
            >
              {t("generic.seeAllArticles")}
            </div>
          </div>
        ) : null}
      </div>
      <div className="mobile-articles-list">
        {articlesMobilePreview ? (
          <div className="content">
            <ReactMarkdown>
              {articlesMobilePreview}
            </ReactMarkdown>
            <div
              className="see-all-button"
              onClick={() => {
                navigate("/articles");
              }}
            >
              {t("generic.seeAllArticles")}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const ArticlesListSectionOLD = ({
  title,
  articlesList,
  mobileArticlesList,
}) => {
  return (
    <div className="articles-list-section">
      <div className="title">{title}</div>
      <div className="desktop-articles-list">
        {articlesList ? (
          <Carousel
            draggable
            swipeable
            infinite={true}
            showDots={true}
            responsive={{
              tablet: {
                breakpoint: { max: 2000, min: 500 },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: { max: 500, min: 0 },
                items: 1,
                partialVisibilityGutter: 40,
              },
            }}
          >
            {articlesList &&
              articlesList.map(
                ({ listOfArticles }, index) => {
                  return (
                    <div className="content" key={index}>
                      <ReactMarkdown>
                        {listOfArticles}
                      </ReactMarkdown>
                    </div>
                  );
                }
              )}
          </Carousel>
        ) : null}
      </div>
      <div className="mobile-articles-list">
        {mobileArticlesList ? (
          <Carousel
            draggable
            swipeable
            infinite={true}
            showDots={true}
            responsive={{
              tablet: {
                breakpoint: { max: 2000, min: 500 },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: { max: 500, min: 0 },
                items: 1,
                partialVisibilityGutter: 40,
              },
            }}
          >
            {mobileArticlesList &&
              mobileArticlesList.map(
                ({ listOfArticles }, index) => {
                  return (
                    <div className="content" key={index}>
                      <ReactMarkdown>
                        {listOfArticles}
                      </ReactMarkdown>
                    </div>
                  );
                }
              )}
          </Carousel>
        ) : null}
      </div>
    </div>
  );
};

const BooksListSection = ({
  title,
  booksAndChaptersDesktopPreview,
  booksAndChaptersMobilePreview,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className="books-list-section">
      <div className="title">{title}</div>
      <div className="desktop-books-list">
        {booksAndChaptersDesktopPreview ? (
          <div className="content">
            <ReactMarkdown>
              {booksAndChaptersDesktopPreview}
            </ReactMarkdown>
            <div
              className="see-all-button"
              onClick={() => {
                navigate("/chapters");
              }}
            >
              {t("generic.seeAllBooks")}
            </div>
          </div>
        ) : null}
      </div>
      <div className="mobile-books-list">
        {booksAndChaptersMobilePreview ? (
          <div className="content">
            <ReactMarkdown>
              {booksAndChaptersMobilePreview}
            </ReactMarkdown>
            <div
              className="see-all-button"
              onClick={() => {
                navigate("/chapters");
              }}
            >
              {t("generic.seeAllBooks")}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const BooksListSectionOLD = ({
  title,
  booksAndChaptersList,
  mobileBooksAndChaptersList,
}) => {
  return (
    <div className="books-list-section">
      <div className="title">{title}</div>
      <div className="desktop-books-list">
        {booksAndChaptersList ? (
          <Carousel
            draggable
            swipeable
            infinite={true}
            showDots={true}
            responsive={{
              tablet: {
                breakpoint: { max: 2000, min: 500 },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: { max: 500, min: 0 },
                items: 1,
                partialVisibilityGutter: 40,
              },
            }}
          >
            {booksAndChaptersList &&
              booksAndChaptersList.map(
                ({ listOfBooksAndChapters }, index) => {
                  return (
                    <div className="content" key={index}>
                      <ReactMarkdown>
                        {listOfBooksAndChapters}
                      </ReactMarkdown>
                    </div>
                  );
                }
              )}
          </Carousel>
        ) : null}
      </div>
      <div className="mobile-books-list">
        {mobileBooksAndChaptersList ? (
          <Carousel
            draggable
            swipeable
            infinite={true}
            showDots={true}
            responsive={{
              tablet: {
                breakpoint: { max: 2000, min: 500 },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: { max: 500, min: 0 },
                items: 1,
                partialVisibilityGutter: 40,
              },
            }}
          >
            {mobileBooksAndChaptersList &&
              mobileBooksAndChaptersList.map(
                ({ listOfBooksAndChapters }, index) => {
                  return (
                    <div className="content" key={index}>
                      <ReactMarkdown>
                        {listOfBooksAndChapters}
                      </ReactMarkdown>
                    </div>
                  );
                }
              )}
          </Carousel>
        ) : null}
      </div>
    </div>
  );
};
