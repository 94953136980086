export const newsletterSubscribe = (data) => {
  return fetch(
    `${process.env.REACT_APP_NODE_BACKEND_URL}/subscribe`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
};

export const onSubmitForm = (values) => {
  return fetch(
    `${process.env.REACT_APP_NODE_BACKEND_URL}/save-form-details`,
    {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
