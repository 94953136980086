import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { Homepage } from "./components/Homepage";
import { Page } from "./components/Page";

import { WhoWeAre } from "./components/WhoWeAre";
import { WhatWeDo } from "./components/WhatWeDo";
import { GetInvolved } from "./components/GetInvolved";
import { Blog } from "./components/Blog";
import { Article } from "./components/Article";
import { TermAndConditions } from "./components/TermsAndConditions";
import { ConfidentialityPolicy } from "./components/ConfidentialityPolity";
import { MindLab } from "./components/MindLab";
import { OurProducts } from "./components/OurProducts";
import { Donate } from "./components/Donate";
import { DonateSecond } from "./components/DonateSecond";

import { MindLabArticles } from "./components/MindLabArticles";
import { MindLabBooksAndChapters } from "./components/MindLabBooksAndChapters";

require("dotenv").config();

import "./styles.css";

import "./i18n";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page component={Homepage} />,
  },
  {
    path: "/who-we-are",
    element: <Page component={WhoWeAre} />,
  },
  {
    path: "/what-we-do",
    element: <Page component={WhatWeDo} />,
  },
  {
    path: "/our-products",
    element: <Page component={OurProducts} />,
  },
  {
    path: "/get-involved",
    element: <Page component={GetInvolved} />,
  },
  {
    path: "/blog",
    element: <Page component={Blog} />,
  },
  {
    path: "/article/:id",
    element: <Page component={Article} />,
  },
  {
    path: "/terms-and-conditions",
    element: <Page component={TermAndConditions} />,
  },
  {
    path: "/confidentiality-policy",
    element: <Page component={ConfidentialityPolicy} />,
  },
  {
    path: "/mindlab",
    element: <Page component={MindLab} />,
  },
  {
    path: "/donate",
    element: <Page component={Donate} />,
  },
  {
    path: "/donate-second",
    element: <Page component={DonateSecond} />,
  },
  {
    path: "/articles",
    element: <Page component={MindLabArticles} />,
  },
  {
    path: "/chapters",
    element: <Page component={MindLabBooksAndChapters} />,
  },
]);

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<RouterProvider router={router} />);
