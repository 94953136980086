import React from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useTranslation } from "react-i18next";

export const TermAndConditions = () => {
  const [text, setText] = React.useState("");
  const { i18n } = useTranslation();
  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/term-and-condition?populate=deep&locale=${i18n.language}`
    )
      .then((response) => response.json())
      .then(({ data }) => {
        // console.log(data.attributes);

        const value = data.attributes.text.toString();
        setText(value);
      });
  }, [i18n.language]);

  return (
    <div className="terms-and-conditions">
      <ReactMarkdown
      // remarkPlugins={[remarkGfm]}
      >
        {text}
      </ReactMarkdown>
    </div>
  );
};
