import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use("ro")
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "ro",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ro: {
        translation: {
          menu: {
            home: "Acasă",
            "who-we-are": "Cine suntem",
            "what-we-do": "Ce facem",
            "our-objectives": "Serviciile noastre",
            mindlab: "MINDCARElab",
            "our-products": "Proiectele noastre",
            "get-involved": "Contact",
            donate: "Donează",
            blog: "Blog",
            back: "Înapoi",
          },
          article: {
            title: "Cele mai recente articole",
            seeFull: "vezi tot articolul",
            seeAllArticles: "vezi toate articolele",
            readMore: "citește mai mult",
            readFull: "citește tot articolul",
          },
          form: {
            title: "Trimite-ne un mesaj!",
            placeholders: {
              firstName: "Prenume",
              lastName: "Nume",
              phone: "Telefon",
              email: "Email",
              message: "Mesaj",
            },
          },
          homepage: {
            "most-recent-articles":
              "Cele mai recente articole",
          },
          footer: {
            "t-and-c": "Termeni și condiții",
            confidentiality:
              "Politica de confidențialitate",
          },
          cookies: {
            title: "Acest site foloseste cookie-uri",
            content: `Pentru a-ți oferi o experiență bună de navigare,
            utilizăm fișiere de tip cookie. Dacă nu ești de
            acord cu utilizarea cookie-urilor, poți să îți
            retragi consimțământul pentru utilizarea
            cookie-urilor prin modificarea setărilor din
            browser-ul tău.`,
            agree: "Sunt de acord",
            reject: "Nu sunt de acord",
          },
          newsletter: {
            invalidEmail: "Adresa de email nu este corectă",
            alreadySubscribed: `Esti deja abonat(a) la newsletterul nostru. Iti
            multumim ca esti parte din comunitate!`,
            subscriptionSuccess: `Te-ai abonat cu succes la newsletterul nostru! Iti multumim!`,
            subscriptionError: `Ceva nu a functionat cum trebuie. Te rugam sa verifici ca ti-ai scris bine adresa de email si sa reincerci.`,
          },
          generic: {
            seeAllArticles: "Vezi toate articolele",
            seeAllBooks: "Vezi toate cărțile",
            partnership: "În parteneriat cu",
          },
          donate: {
            donateButtonLabel: "Donează online",
            disclaimerStrapi:
              "* pentru procesarea tranzacțiilor online folosim platforma",
          },
          form35: {
            firstName: "Prenume",
            lastName: "Nume",
            fatherInitial: "Inițiala tatălui",
            street: "Strada",
            number: "Număr",
            block: "Bloc",
            buildingEntrance: "Scara",
            floor: "Etaj",
            apartment: "Apartament",
            county: "Județ",
            city: "Localitate",
            postalCode: "Cod poștal",
            cnp: "Cod numeric personal / Număr de identificare fiscală",
            email: "Email",
            phone: "Telefon",
            twoYears:
              "Opțiune privind distribuirea sumei pentru o perioadă de 2 ani",
            signatureTitle:
              "Semnează în căsuța albă de mai jos",
            signatureClearButtonLabel: "Șterge căsuța",
            gdpr: "Sunt de acord cu prelucrarea datelor mele cu caracter personal în scopul depunerii la ANAF a formularului 230",
            promotions:
              "Sunt de acord ca asociația Mind Care For All să mă contacteze cu informații despre contriuția mea la activitățile realizate",
            send: "Trimite",
          },
          errors: {
            required: "Câmp obligatoriu!",
            min13: "Minim 13 caractere!",
            email: "Email invalid!",
          },
        },
      },
      en: {
        translation: {
          menu: {
            home: "Home",
            "who-we-are": "Who we are",
            "what-we-do": "What we do",
            "our-objectives": "Our services",
            mindlab: "MINDCARElab",
            "our-products": "Our projects",
            "get-involved": "Contact",
            donate: "Donate",
            blog: "Blog",
            back: "Back",
          },
          article: {
            title: "Most recent articles",
            seeFull: "see full article",
            readMore: "read more",
            readFull: "read full article",
            seeAllArticles: "see all articles",
          },
          form: {
            title: "Send us a message!",
            placeholders: {
              firstName: "First name",
              lastName: "Last name",
              phone: "Phone number",
              email: "Email",
              message: "Message",
            },
          },
          homepage: {
            "most-recent-articles": "Most recent articles",
          },
          footer: {
            "t-and-c": "Terms and conditions",
            confidentiality: "Confidentiality policy",
          },
          cookies: {
            title: "This site uses cookies",
            content: `To give you a good browsing experience,
            we use cookies. You can
            withdraw consent for use
            cookies by changing the settings in
            your browser.`,
            agree: "I agree",
            reject: "I don't agree",
          },
          newsletter: {
            invalidEmail: "Invalid email address",
            alreadySubscribed: `You are already subscribed to our newsletter. Thank you for being part of our community!`,
            subscriptionSuccess: `You successfully subscribed to our newsletter. Thank you!`,
            subscriptionError: `Something went wrong. Please check the email address and try again in a few minutes`,
          },
          generic: {
            seeAllArticles: "See all articles",
            seeAllBooks: "See all books",
            partnership: "In partnership with",
          },
          donate: {
            donateButtonLabel: "Donate online",
            disclaimerStrapi:
              "* for the online processing of the transactions we use the platform",
          },
          form35: {
            firstName: "First name",
            lastName: "Last name",
            fatherInitial: "Father's initial",
            street: "Street",
            number: "Number",
            block: "Building",
            buildingEntrance: "Building entrance",
            floor: "Floor",
            apartment: "Apartment",
            county: "County",
            city: "City",
            postalCode: "Postal code",
            cnp: "Personal identification number / Tax identification number",
            email: "Email",
            phone: "Phone number",
            twoYears:
              "Option to distribute the amount over a period of 2 years",
            signatureTitle: "Sign in the white box below",
            signatureClearButtonLabel: "Clear the box",
            gdpr: "I agree to the processing of my personal data for the purpose of submitting form 230 to ANAF.",
            promotions:
              "I agree that the Mind Care For All association may contact me with information about my contribution to the activities carried out.",
            send: "Send",
          },
          errors: {
            required: "Required field!",
            min13: "Minimum 13 characters!",
            email: "Invalid email!",
          },
        },
      },
    },
  });

export default i18n;
