import React from "react";
import { useTranslation } from "react-i18next";

export const CookiesConsentModal = (props) => {
  const { Component } = props;
  const [isModalVisible, setIsModalVisible] =
    React.useState(false);

  const { t } = useTranslation();

  const acceptCookies = () => {
    localStorage.setItem("cookie-consent", "yes");
    setIsModalVisible(false);
  };

  const declineCookies = () => {
    localStorage.setItem("cookie-consent", "no");
    setIsModalVisible(false);
  };

  const closeModalWithoutResponsing = () => {
    localStorage.setItem(
      "cookie-consent-ignored",
      Date.now()
    );
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    const cookieConsent = localStorage.getItem(
      "cookie-consent"
    );

    const cookieConsentIgnored = localStorage.getItem(
      "cookie-consent-ignored"
    );

    if (!cookieConsentIgnored && !cookieConsent) {
      // console.log("modal should be visible");
      setIsModalVisible(true);
    }
  }, [Component]);

  if (!isModalVisible) {
    return null;
  }

  return (
    <div className="cookies-consent-modal-wrapper">
      <div className="cookies-consent-modal">
        <div className="content">
          <div className="title">{t("cookies.title")}</div>

          <div className="text">{t("cookies.content")}</div>

          <div className="buttons-wrapper">
            <div
              className="accept"
              onClick={() => {
                acceptCookies();
              }}
            >
              {t("cookies.agree")}
            </div>
            <div
              className="decline"
              onClick={() => {
                declineCookies();
              }}
            >
              {t("cookies.reject")}
            </div>
          </div>
        </div>
        <div
          className="close-button"
          onClick={() => {
            closeModalWithoutResponsing();
          }}
        >
          <CloseButton />
        </div>
      </div>
    </div>
  );
};

const CloseButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
    </g>
  </svg>
);
