import React from "react";
import { useTranslation } from "react-i18next";

import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export const Testimonials = () => {
  const { i18n } = useTranslation();

  const [testimonials, setTestimonials] =
    React.useState(null);

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/testimonials?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        console.log(data);

        setTestimonials(data.map((d) => d.attributes));
      });
  }, []);

  return (
    <div className="testimonials-wrapper">
      {testimonials?.map((testimonial, index) => (
        <Testimonial {...testimonial} key={index} />
      ))}
    </div>
  );
};

const Testimonial = (props) => {
  const { name, image, description } = props;

  return (
    <div className="testimonial-wrapper">
      <FormatQuoteIcon />
      <div className="image-wrapper">
        <img src={image?.data?.attributes?.url} />
      </div>

      <div className="description">{description}</div>
      <div className="name">{name}</div>
    </div>
  );
};
